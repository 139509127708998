import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RootState, setPage, setProfile } from '../redux/store';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { IProfile } from '../redux/model';
import { EGender, GRADES, PROFILE_ID_PARAM } from '../constants';
import { StringHelper } from '../helpers/StringHelper';
import { NumberHelper } from '../helpers/NumberHelper';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AppHelper } from '../helpers/AppHelper';

const ProfileScreen: React.FC = () => {
    const profile = useSelector((state: RootState) => state.app.profile);
    const [name, setName] = useState<string>(profile?.name || '');
    const [gender, setGender] = useState<EGender | null>(
        profile?.gender || null
    );
    const [grade, setGrade] = useState<number | null>(profile?.grade || null);
    const [interests, setInterests] = useState<string>(
        profile?.interests || ''
    );
    const [busy, setBusy] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleChangeGender = (event: SelectChangeEvent) => {
        setGender((event.target.value as EGender) || null);
    };

    const handleChangeGrade = (event: SelectChangeEvent) => {
        setGrade(parseInt(event.target.value, 10) || 0);
    };

    const handleSave = async () => {
        if (StringHelper.isNotEmpty(name) && NumberHelper.isNotEmpty(grade)) {
            setBusy(true);
            try {
                if (profile) {
                    const response = await axios.put(
                        `${apiUrl}/profile?${PROFILE_ID_PARAM}=${profile.key}`,
                        {
                            name,
                            gender,
                            grade,
                            interests,
                        }
                    );
                    setBusy(false);
                    if (response?.data?.profile?.key) {
                        const updatedProfile: IProfile = response.data.profile;
                        dispatch(setProfile(updatedProfile));
                        dispatch(setPage(undefined));
                    }
                } else {
                    const response = await axios.post(`${apiUrl}/profile`, {
                        name,
                        gender,
                        grade,
                        interests,
                    });
                    setBusy(false);
                    if (response?.data?.profile?.key) {
                        const newProfile: IProfile = response.data.profile;
                        dispatch(setProfile(newProfile));
                        navigate(`?${PROFILE_ID_PARAM}=${newProfile.key}`);
                    }
                }
            } catch (error) {
                setBusy(false);
                console.error('Error saving profile:', error);
            }
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%' }}
        >
            {busy ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        width: '50%',
                        maxWidth: 480,
                        gap: 2,
                    }}
                >
                    <CircularProgress
                        color="primary"
                        size="large"
                        sx={{ width: '50%', maxWidth: 128 }}
                    />
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        maxWidth: 480,
                        gap: 4,
                    }}
                >
                    <Typography align="center" variant="h1">
                        Hi, tell me about yourself!
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ width: '100%', gap: 2 }}
                    >
                        <TextField
                            autoFocus={true}
                            label="First name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                            slotProps={{
                                htmlInput: {
                                    maxLength: 32,
                                },
                            }}
                        />
                        <FormControl>
                            <InputLabel id="gender-select-label">
                                Gender
                            </InputLabel>
                            <Select
                                labelId="gender-select-label"
                                value={gender || ''}
                                label="Gender"
                                onChange={handleChangeGender}
                            >
                                <MenuItem value={EGender.FEMALE}>Girl</MenuItem>
                                <MenuItem value={EGender.MALE}>Boy</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="grade-select-label">
                                Grade
                            </InputLabel>
                            <Select
                                labelId="grade-select-label"
                                value={grade?.toString() || ''}
                                label="Grade"
                                onChange={handleChangeGrade}
                            >
                                {GRADES.map((grade, index) => (
                                    <MenuItem key={index} value={grade}>
                                        {AppHelper.getGradeName(grade)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Topics of interest"
                            variant="outlined"
                            placeholder="e.g. animals, sports, video games"
                            value={interests}
                            onChange={(e) => setInterests(e.target.value)}
                            fullWidth
                            margin="normal"
                            slotProps={{
                                htmlInput: {
                                    maxLength: 64,
                                },
                            }}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default ProfileScreen;
