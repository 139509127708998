import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setError, setPage } from '../redux/store';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import {
    Box,
    Button,
    CircularProgress,
    Fab,
    TextField,
    Typography,
    LinearProgress,
} from '@mui/material';
import { PROFILE_ID_PARAM } from '../constants';
import { makeStyles } from '@mui/styles';
import { StringHelper } from '../helpers/StringHelper';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { EPage, IProgress, ISpokenWord, ITopic } from '../redux/model';
import { AppHelper } from '../helpers/AppHelper';
import TopicComponent from '../components/Topic';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { NumberHelper } from '../helpers/NumberHelper';
import spellingTheme from '../themes/spelling';
import topicsTheme from '../themes/topics';

enum ESoundState {
    INIT,
    WAITING,
    PLAYING,
    DONE,
    CANCELLED,
}

const SpellingScreen: React.FC = () => {
    const theme = useTheme();
    const classes = makeStyles({
        container: {
            maxWidth: 640,
            margin: 'auto',
            marginTop: '2rem',
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        textFieldContainer: {
            width: '100%',
            position: 'relative',
            gap: 1,
        },
        inputText: {
            textAlign: 'center',
            fontSize: '4rem !important',
            padding: '0.6rem !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2rem !important',
            },
        },
        mark: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: -72,
            fontSize: '4rem !important',
            zIndex: 1,
            backgroundColor: '#121212',
            borderRadius: '50%',
            [theme.breakpoints.down('sm')]: {
                visibility: 'hidden',
            },
        },
        word: {
            borderBottomStyle: 'solid',
            borderBottomColor: 'transparent',
            borderBottomWidth: 3,
        },
        wordUnderline: {
            borderBottomColor: theme.palette.primary.main,
            position: 'relative',
            top: '-0.8rem',
            [theme.breakpoints.down('sm')]: {
                top: '-0.5rem',
            },
        },
        correctWord: {
            color: theme.palette.secondary.main,
        },
        incorrectWord: {
            color: theme.palette.primary.main,
        },
        hidden: {
            visibility: 'hidden',
        },
    })();
    const profile = useSelector((state: RootState) => state.app.profile);
    const dispatch = useDispatch();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [spokenWord, setSpokenWord] = useState<ISpokenWord | undefined>();
    const [progress, setProgress] = useState<IProgress | undefined>();
    const [completeTopic, setCompleteTopic] = useState<ITopic | undefined>();
    const [missingWordSentence, setMissingWordSentence] = useState<string>('');
    const [spelledWord, setSpelledWord] = useState<string>('');
    const [correct, setCorrect] = useState<boolean | undefined>(undefined);
    const [audioQueue, setAudioQueue] = useState<HTMLAudioElement[]>([]);
    const [soundState, setSoundState] = useState<ESoundState>(ESoundState.INIT);
    const [cachedAudio, setCachedAudio] = useState<string | undefined>();
    const [lettersShown, setLettersShown] = useState<number>(0);
    const [playingAudio, setPlayingAudio] = useState<
        HTMLAudioElement | undefined
    >();
    const [enterPressed, setEnterPressed] = useState<number | undefined>();
    const dingSound = useRef(new Audio('/sounds/ding.mp3'));
    const textFieldRef = useRef<HTMLInputElement | null>(null);
    const setEnterPressedRef = useRef(setEnterPressed);

    dingSound.current.volume = 0.3;

    const handleSubmit = () => {
        if (
            spelledWord.trim().toLowerCase() === spokenWord!.word.toLowerCase()
        ) {
            markCorrect();
        } else {
            markIncorrect();
        }
    };
    const handleNextWord = () => {
        fetchNextWord();
    };

    const markCorrect = () => {
        dingSound.current.play();
        setCorrect(true);
        setLettersShown((spokenWord?.word || '').length);
    };

    const handleComplete = () => {
        fetchTopic();
    };

    const fetchTopic = async () => {
        try {
            const response = await axios.get(
                `${apiUrl}/topic?${PROFILE_ID_PARAM}=${profile?.key}`
            );
            if (response?.data?.topic) {
                const topic: ITopic = response.data.topic;
                setCompleteTopic(topic);
            }
        } catch (error) {
            console.error('Error fetching topic:', error);
        }
    };

    const markIncorrect = () => {
        const commonPrefix = StringHelper.getCommonPrefix(
            spelledWord,
            spokenWord?.word || ''
        );
        let lettersToShow = commonPrefix.length;
        if (lettersToShow >= (spokenWord?.word || '').length) {
            lettersToShow--;
        } else {
            // if (lettersToShow < (spokenWord?.word || '').length - 1) {
            lettersToShow++;
        }
        setLettersShown(lettersToShow);
        // setSpelledWord(commonPrefix);
        if (correct !== false) {
            setCorrect(false);
            boostCurrentWord();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSpelledWord(event.target.value.replace(/^ +/, ''));
    };

    const playAudio = (audioUrl: string) => {
        if (playingAudio) {
            // stop previous audio
            playingAudio.pause();
            playingAudio.currentTime = 0;
        }
        const audio = new Audio(audioUrl);
        audio.addEventListener('ended', () => {
            setSoundState(ESoundState.DONE);
        });
        setSoundState(ESoundState.PLAYING);
        audio.play();
        setPlayingAudio(audio);
    };

    const playSound = async () => {
        if (cachedAudio !== undefined) {
            playAudio(cachedAudio);
        } else {
            const response = await axios.get(
                `${apiUrl}/speakSentence?${PROFILE_ID_PARAM}=${profile?.key}`
            );
            if (response?.data) {
                try {
                    const audio = JSON.parse(response.data);
                    const audioArray = new Uint8Array(audio.audio.data);
                    const audioBlob = new Blob([audioArray], {
                        type: 'audio/mpeg',
                    });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setCachedAudio(audioUrl);
                    playAudio(audioUrl);
                } catch (error) {
                    console.error('Error parsing audio response:', error);
                }
            }
        }
    };

    const speakSentence = () => {
        setSoundState(ESoundState.WAITING);
        playSound();
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    };

    const cancelSpeaking = () => {
        setCachedAudio(undefined);
        audioQueue.forEach((audio) => {
            setSoundState(ESoundState.CANCELLED);
            audio.pause();
            audio.currentTime = 0;
        });
        setAudioQueue([]);
    };

    const fetchCurrentWord = async () => {
        try {
            const response = await axios.get(
                `${apiUrl}/currentWord?${PROFILE_ID_PARAM}=${profile?.key}`
            );
            if (response?.data) {
                const progress: IProgress = response!.data.progress;
                if (progress.value >= progress.max) {
                    reset();
                    handleComplete();
                } else {
                    setProgress(progress);
                    setSpokenWord(response!.data!.word);
                }
            } else {
                dispatch(setError('No word found'));
            }
        } catch (error) {
            dispatch(setError(`Error fetching current word: ${error}`));
        }
    };

    const reset = () => {
        setCorrect(undefined);
        setSpokenWord(undefined);
        // setProgress(undefined);
        setMissingWordSentence('');
        setSpelledWord('');
        setSoundState(ESoundState.INIT);
        setCompleteTopic(undefined);
        cancelSpeaking();
    };

    const fetchNextWord = async () => {
        reset();
        try {
            const response = await axios.get(
                `${apiUrl}/nextWord?${PROFILE_ID_PARAM}=${profile?.key}`
            );
            const progress: IProgress = response!.data.progress;
            if (progress.value >= progress.max) {
                handleComplete();
            } else {
                setProgress(response!.data.progress);
                setSpokenWord(response!.data.word);
            }
        } catch (error) {
            dispatch(setError(`Error fetching next word: ${error}`));
        }
    };

    const boostCurrentWord = async () => {
        try {
            await axios.get(
                `${apiUrl}/boostCurrentWord?${PROFILE_ID_PARAM}=${profile?.key}`
            );
        } catch (error) {
            dispatch(setError(`Error boosting current word: ${error}`));
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key.length === 1) {
            const value = event.key;
            const regex = /^[A-Za-z '-]$/; // Update regex to desired characters

            if (!regex.test(value)) {
                event.preventDefault();
            }
        }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            setEnterPressedRef.current(new Date().getTime());
        }
    };

    const handleDoneClick = () => {
        dispatch(setPage(EPage.SELECT_TOPIC));
    };

    const handleRedoClick = async () => {
        try {
            const response = await axios.post(
                `${apiUrl}/topic/reset?${PROFILE_ID_PARAM}=${profile?.key}`
            );
            if (response?.data === true) {
                setCompleteTopic(undefined);
                fetchCurrentWord();
            }
        } catch (error) {
            console.error('Error redoing topic:', error);
        }
    };

    useEffect(() => {
        if (spokenWord) {
            setMissingWordSentence(
                StringHelper.replaceWordInSentence(
                    spokenWord!.word,
                    spokenWord!.sentence
                )
            );
        }
    }, [spokenWord]);

    useEffect(() => {
        if (missingWordSentence) {
            speakSentence();
        }
    }, [missingWordSentence]);

    useEffect(() => {
        if (spelledWord.length > 0 && !completeTopic) {
            if (correct) {
                handleNextWord();
            } else {
                handleSubmit();
            }
        }
    }, [enterPressed]);

    const onUnload = () => {
        window.removeEventListener('keyup', handleKeyUp);
        cancelSpeaking();
    };

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);
        fetchCurrentWord();
        return onUnload; // DEV:TODO - doesn't work
    }, []);

    const sentenceFontSize = NumberHelper.getScaledValueInRange(
        missingWordSentence.length,
        48,
        144,
        2.3,
        3.3
    );

    return completeTopic !== undefined ? (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.container}
            sx={{ height: '75%', gap: 4 }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ gap: 4 }}
            >
                <Typography variant="h2" align="center">
                    {AppHelper.getGradeName(profile!.grade)}
                </Typography>
                <Typography variant="h3" align="center">
                    You've completed
                    <br />
                    <Typography component="span" variant="h3" color="secondary">
                        {profile!.subject} - {profile!.topic}
                    </Typography>
                </Typography>
                <ThemeProvider theme={topicsTheme}>
                    <TopicComponent topic={completeTopic} hideTitle={true} />
                </ThemeProvider>
                <Button variant="contained" onClick={handleDoneClick}>
                    Select another topic
                </Button>
                <Button variant="text" onClick={handleRedoClick}>
                    Redo this topic
                </Button>
            </Box>
        </Box>
    ) : (
        <ThemeProvider theme={spellingTheme}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.container}
                sx={{ gap: { xs: 3, sm: 3, md: 6 } }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ width: '100%', gap: { xs: 1, sm: 1, md: 2 } }}
                >
                    <Typography variant="h2" align="center">
                        {AppHelper.getGradeName(profile!.grade)}{' '}
                        {profile!.subject} - {profile!.topic}
                    </Typography>
                    {progress !== undefined && (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress
                                color="secondary"
                                variant="determinate"
                                value={(progress.value / progress.max) * 100}
                                sx={{ height: 12 }}
                            />
                        </Box>
                    )}
                </Box>
                {profile !== undefined &&
                spokenWord !== undefined &&
                (soundState === ESoundState.PLAYING ||
                    soundState === ESoundState.DONE) ? (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: { xs: 1, sm: 1, md: 2 } }}
                        >
                            {soundState === ESoundState.PLAYING && (
                                <Fab
                                    color="primary"
                                    sx={{
                                        width: 72,
                                        height: 72,
                                        pointerEvents: 'none',
                                        backgroundColor:
                                            theme.palette.secondary.main,
                                    }}
                                >
                                    <VolumeUp sx={{ fontSize: 48 }} />
                                </Fab>
                            )}
                            {soundState === ESoundState.DONE && (
                                <Fab
                                    color={correct ? 'secondary' : 'primary'}
                                    sx={{ width: 72, height: 72 }}
                                    onClick={speakSentence}
                                >
                                    <VolumeUp sx={{ fontSize: 48 }} />
                                </Fab>
                            )}
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                    fontSize: `${sentenceFontSize.toFixed(1)}rem !important`,
                                    [theme.breakpoints.down('sm')]: {
                                        fontSize: `${(sentenceFontSize * 0.6).toFixed(1)}rem !important`,
                                    },
                                }}
                            >
                                <ReactMarkdown
                                    components={{
                                        em: ({ node, ...props }) => (
                                            <span
                                                className={
                                                    classes.word +
                                                    ' ' +
                                                    (correct === true
                                                        ? classes.correctWord
                                                        : correct !== undefined
                                                          ? classes.incorrectWord
                                                          : classes.wordUnderline)
                                                }
                                            >
                                                <span
                                                    className={
                                                        correct !== undefined
                                                            ? undefined
                                                            : classes.hidden
                                                    }
                                                >
                                                    {props
                                                        .children!.toString()
                                                        .split('')
                                                        .map(
                                                            (letter, index) => (
                                                                <span
                                                                    key={index}
                                                                    className={
                                                                        index >=
                                                                        lettersShown
                                                                            ? classes.hidden
                                                                            : ''
                                                                    }
                                                                >
                                                                    {letter}
                                                                </span>
                                                            )
                                                        )}
                                                </span>
                                            </span>
                                        ),
                                        p: ({ node, ...props }) => (
                                            <p style={{ textAlign: 'left' }}>
                                                {props.children}
                                            </p>
                                        ),
                                    }}
                                >
                                    {missingWordSentence}
                                </ReactMarkdown>
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ gap: { xs: 1, sm: 1, md: 2 } }}
                        >
                            <Box
                                className={classes.textFieldContainer}
                                display="flex"
                                flexDirection="column"
                            >
                                {correct === true && (
                                    <CheckCircle
                                        className={classes.mark}
                                        sx={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    />
                                )}
                                {correct === false && (
                                    <Cancel
                                        className={classes.mark}
                                        sx={{ color: 'red' }}
                                    />
                                )}
                                <TextField
                                    inputRef={textFieldRef}
                                    disabled={correct === true}
                                    autoFocus={true}
                                    variant="outlined"
                                    onKeyDown={handleKeyDown}
                                    value={spelledWord}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    label={
                                        StringHelper.isEmpty(spelledWord)
                                            ? 'Type the word here'
                                            : ''
                                    }
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                    onCopy={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                    slotProps={{
                                        htmlInput: {
                                            maxLength: 32,
                                            spellCheck: false,
                                        },
                                        input: {
                                            classes: {
                                                input: classes.inputText,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            {correct === true ? (
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleNextWord}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    className={
                                        classes.button +
                                        (spelledWord.length === 0
                                            ? ' ' + classes.hidden
                                            : '')
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            )}
                        </Box>
                    </>
                ) : (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: '100%' }}
                    >
                        <CircularProgress
                            color="primary"
                            size="large"
                            sx={{ width: '50%', maxWidth: 128 }}
                        />
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default SpellingScreen;
