import { EGender } from '../constants';

export interface IWord {
    word: string;
    sentence: string;
    score: number;
}

export interface IProfile {
    key: string;
    createdAt: string;
    name: string;
    gender: EGender;
    subject: string;
    topic: string;
    interests: string;
    funnyWords: string;
    grade: number;
    wordsByGrade: {
        [grade: string]: {
            wordList: IWord[];
            currentWordIndex: number;
        };
    };
}

export interface IAiResponse {
    text?: string;
    audio?: string;
}

export interface ISpokenWord {
    word: string;
    sentence: string;
    voice: string;
}

export interface IProgress {
    value: number;
    max: number;
}

export enum EPage {
    SPELLING,
    PROFILE,
    SELECT_TOPIC,
}

export interface ITopic {
    grade: number;
    subject: string;
    name: string;
    score?: number;
}
