import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { RootState, setPage, setProfile } from '../redux/store';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { EPage, IProfile, ITopic } from '../redux/model';
import { GRADES, PROFILE_ID_PARAM } from '../constants';
import { StringHelper } from '../helpers/StringHelper';
import { NumberHelper } from '../helpers/NumberHelper';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AppHelper } from '../helpers/AppHelper';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import topicsTheme from '../themes/topics';
import ButtonBase from '@mui/material/ButtonBase';
import TopicComponent from '../components/Topic';
import { Grid2 } from '@mui/material';

const TopicScreen: React.FC = () => {
    const theme = useTheme();
    const profile = useSelector((state: RootState) => state.app.profile);
    const [grade, setGrade] = useState<number | null>(profile?.grade || null);
    const [topics, setTopics] = useState<ITopic[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const dispatch = useDispatch();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleChangeGrade = (event: SelectChangeEvent) => {
        setGrade(parseInt(event.target.value, 10) || 0);
    };

    const selectTopic = async (topic: ITopic) => {
        if (
            NumberHelper.isNotEmpty(grade) &&
            StringHelper.isNotEmpty(topic.subject) &&
            StringHelper.isNotEmpty(topic.name)
        ) {
            setBusy(true);
            try {
                if (profile) {
                    const response = await axios.put(
                        `${apiUrl}/profile/topic?${PROFILE_ID_PARAM}=${profile.key}`,
                        {
                            grade,
                            subject: topic.subject,
                            topic: topic.name,
                        }
                    );
                    setBusy(false);
                    if (response?.data?.profile?.key) {
                        const updatedProfile: IProfile = response.data.profile;
                        dispatch(setProfile(updatedProfile));
                        dispatch(setPage(EPage.SPELLING));
                    }
                }
            } catch (error) {
                setBusy(false);
                console.error('Error saving profile:', error);
            }
        }
    };

    const fetchTopics = async () => {
        try {
            const response = await axios.get(
                `${apiUrl}/topics?${PROFILE_ID_PARAM}=${profile?.key}`
            );
            if (response?.data?.topics) {
                const topicList: ITopic[] = response.data.topics;
                setTopics(topicList);
            }
            setBusy(false);
        } catch (error) {
            console.error('Error fetching topics:', error);
            setBusy(false);
        }
    };

    useEffect(() => {
        setBusy(true);
        fetchTopics();
    }, []);

    return (
        <Box
            display="flex"
            justifyContent="center"
            sx={{ marginTop: '2rem', height: '100%' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ padding: '1rem', width: '100%', maxWidth: 1200, gap: 4 }}
            >
                {busy ? (
                    <CircularProgress
                        color="primary"
                        size="large"
                        sx={{ width: '50%', maxWidth: 128 }}
                    />
                ) : (
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ width: '100%', gap: 4 }}
                        >
                            <FormControl
                                sx={{
                                    width: '33%',
                                    minWidth: 240,
                                    maxWidth: 360,
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                <Select
                                    variant="standard"
                                    labelId="grade-select-label"
                                    value={grade?.toString() || ''}
                                    onChange={handleChangeGrade}
                                >
                                    {GRADES.map((grade, index) => (
                                        <MenuItem key={index} value={grade}>
                                            {AppHelper.getGradeName(grade)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography align="center" variant="h1">
                                Select a topic
                            </Typography>
                            <ThemeProvider theme={topicsTheme}>
                                <Grid2
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                    sx={{ width: '100%' }}
                                >
                                    {topics
                                        .filter((t) => t.grade === grade)
                                        .map((t, index) => (
                                            <Grid2
                                                key={index}
                                                size={{
                                                    xs: 12,
                                                    sm: 6,
                                                    md: 6,
                                                    lg: 4,
                                                }}
                                            >
                                                <ButtonBase
                                                    onClick={() =>
                                                        selectTopic(t)
                                                    }
                                                    sx={{ width: '100%' }}
                                                >
                                                    <TopicComponent topic={t} />
                                                </ButtonBase>
                                            </Grid2>
                                        ))}
                                </Grid2>
                            </ThemeProvider>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default TopicScreen;
