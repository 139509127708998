export const PROFILE_ID_PARAM = 'key';
export const enum EAppState {
    LOADING,
    LOADED,
    PLAYING,
}

export enum EGender {
    MALE = 'male',
    FEMALE = 'female',
}

export const GRADES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
