// src/store.ts
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EPage, IProfile } from './model';
import { EAppState } from '../constants';

interface AppState {
    appState: EAppState;
    profile: IProfile | undefined;
    currentPage: EPage | undefined;
    errorMessage: string | undefined;
}

const initialState: AppState = {
    appState: EAppState.LOADING,
    profile: undefined,
    currentPage: undefined,
    errorMessage: undefined,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setProfile(state, action: PayloadAction<IProfile | undefined>) {
            state.profile = action.payload;
        },
        setAppState(state, action: PayloadAction<EAppState>) {
            state.appState = action.payload;
        },
        setPage(state, action: PayloadAction<EPage | undefined>) {
            state.currentPage = action.payload;
        },
        setError(state, action: PayloadAction<string | undefined>) {
            state.errorMessage = action.payload;
            console.error(state.errorMessage);
        },
    },
});

export const { setProfile, setAppState, setPage, setError } = appSlice.actions;

const store = configureStore({
    reducer: {
        app: appSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
