import MobileDetect from 'mobile-detect';

export class AppHelper {
    public static getGradeName = (grade: number): string => {
        switch (grade) {
            case 0:
                return 'Kindergarten';
            case 1:
                return '1st grade';
            case 2:
                return '2nd grade';
            case 3:
                return '3rd grade';
            case 4:
                return '4th grade';
            case 5:
                return '5th grade';
            case 6:
                return '6th grade';
            case 7:
                return '7th grade';
            case 8:
                return '8th grade';
            case 9:
                return '9th grade';
            case 10:
                return '10th grade';
            case 11:
                return '11th grade';
            case 12:
                return '12th grade';
            default:
                throw new Error(`Invalid grade, ${grade}`);
        }
    };

    public static isMobileDevice = (): boolean => {
        const md = new MobileDetect(navigator.userAgent);
        return !!md.mobile();
    };
}
