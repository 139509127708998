import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setAppState } from '../redux/store';
import { EAppState } from '../constants';
import { Box, Button } from '@mui/material';
import LogoComponent from '../components/Logo';
import { AppHelper } from '../helpers/AppHelper';

const StartupScreen: React.FC = () => {
    const dispatch = useDispatch();
    const appState = useSelector((state: RootState) => state.app.appState);

    const handleClick = () => {
        if (AppHelper.isMobileDevice()) {
            document.documentElement.requestFullscreen();
        }
        dispatch(setAppState(EAppState.PLAYING));
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: 4 }}
        >
            <LogoComponent size="large" />
            <Button
                variant="contained"
                onClick={handleClick}
                disabled={appState === EAppState.LOADING}
            >
                Start
            </Button>
        </Box>
    );
};

export default StartupScreen;
