import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Star from '@mui/icons-material/Star';
import { ITopic } from '../redux/model';
import Box from '@mui/material/Box';

interface IProps {
    topic: ITopic;
    hideTitle?: boolean;
}

const TopicComponent: React.FC<IProps> = ({ topic, hideTitle }) => {
    const classes = makeStyles({
        stars: {
            height: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })();

    const score = topic.score || 0;
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: { xs: 1, sm: 1, md: 2 } }}
        >
            <Typography align="center" variant="caption">
                {score !== 0
                    ? score < 0
                        ? 'In progress'
                        : `${score}%`
                    : 'Not started'}
            </Typography>
            {!hideTitle && (
                <Box>
                    <Typography align="center" variant="body2">
                        {topic.subject}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                        color={
                            Math.abs(score) > 0
                                ? score === 1
                                    ? 'secondary'
                                    : 'primary'
                                : undefined
                        }
                    >
                        {topic.name}
                    </Typography>
                </Box>
            )}
            <div className={classes.stars}>
                {[...Array(Math.max(1, Math.round(Math.abs(score) / 20)))].map(
                    (_, starIndex) => (
                        <Star
                            key={starIndex}
                            color={
                                score <= 0
                                    ? undefined
                                    : score === 100
                                      ? 'secondary'
                                      : 'primary'
                            }
                            sx={{
                                opacity: score <= 0 ? 0.54 : undefined,
                            }}
                        />
                    )
                )}
            </div>
        </Box>
    );
};

export default TopicComponent;
