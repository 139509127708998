import { green, orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: orange[500],
        },
        secondary: {
            main: green.A400,
        },
    },
    typography: {
        fontFamily: 'Poppins',
        fontSize: 24,
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '1rem',
            textTransform: 'uppercase',
        },
        h3: {
            fontSize: '2.4rem',
        },
        body1: {
            fontSize: '2rem',
        },
        body2: {
            fontSize: '2.4rem',
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                shrink: {
                    transform: 'translate(14px, -16px) scale(0.75)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                    lineHeight: 1,
                    padding: '2rem 3rem',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
            },
        },
    },
});

const responsiveTheme = createTheme(mainTheme, {
    typography: {
        h1: {
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
            },
        },
        h2: {
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
            },
        },
        h3: {
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '2rem',
            },
        },
        body1: {
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1.4rem',
            },
        },
        body2: {
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1.6rem',
            },
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    [mainTheme.breakpoints.down('sm')]: {
                        fontSize: '1.8rem',
                        padding: '1rem 1.6rem',
                    },
                },
            },
        },
    },
});

export default responsiveTheme;
